import { safeSorter } from "./utils"

export const shootingColumns = [
  {
    key: "A",
    title: "Name of Victim",
    dataIndex: "Name_of_Victim",
    sorter: (a, b) => safeSorter(a.Name_of_Victim, b.Name_of_Victim),
  },
  {
    key: "B",
    title: "Officer Responsible - Affiliation",
    dataIndex: "Officer_Involved___affiliation____of_known_shootings_",
    sorter: (a, b) =>
      safeSorter(
        a.Officer_Involved___affiliation____of_known_shootings_,
        b.Officer_Involved___affiliation____of_known_shootings_
      ),
  },
  {
    key: "C",
    title: "Case Number",
    dataIndex: "Case_Number",
    sorter: (a, b) => safeSorter(a.Case_Number, b.Case_Number),
  },
  {
    key: "D",
    title: "Location",
    dataIndex: "Location",
    sorter: (a, b) => safeSorter(a.Location, b.Location),
  },
  {
    key: "E",
    title: "Date of Incident",
    dataIndex: "Date_of_Incident",
    sorter: (a, b) => safeSorter(a.Date_of_Incident, b.Date_of_Incident),
  },
  {
    key: "F",
    title: "Age of Victim",
    dataIndex: "Age",
    sorter: (a, b) => safeSorter(a.Age, b.Age),
  },
  {
    key: "G",
    title: "Race of Victim",
    dataIndex: "Race",
    sorter: (a, b) => safeSorter(a.Race, b.Race),
  },
  {
    key: "H",
    title: "Weapon (per LASD)",
    dataIndex: "Weapon__per_LASD_",
    sorter: (a, b) => safeSorter(a.Weapon__per_LASD_, b.Weapon__per_LASD_),
  },
  {
    key: "I",
    title: "Outcome/Cause of Death",
    dataIndex: "Outcome__Cause_of_Death",
    sorter: (a, b) =>
      safeSorter(a.Outcome__Cause_of_Death, b.Outcome__Cause_of_Death),
  },
  {
    key: "J",
    title: "Station/Assignment at Incident",
    dataIndex: "Station_Assignment_at_Incident",
    sorter: (a, b) =>
      safeSorter(
        a.Station_Assignment_at_Incident,
        b.Station_Assignment_at_Incident
      ),
  },
]
