export const createMarkup = text => {
  return { __html: text }
}

export const safeSorter = (a, b) => {
  if (a) {
    return b ? a.localeCompare(b) : -1
  } else if (b) {
    return a ? b.localeCompare(a) : 1
  }
}
