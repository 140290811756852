import React, { useState, useCallback, forwardRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GoogleMapsProvider } from "@ubilabs/google-maps-react-hooks"
import {
  MarkerClusterer,
  SuperClusterAlgorithm,
} from "@googlemaps/markerclusterer"

const MapCanvas = forwardRef((_props, ref) => (
  <div ref={ref} style={{ height: "100vh" }} />
))

// map setup
const mapDefaults = {
  center: {
    lat: 34.204595,
    lng: -118.1973770785,
  },
  zoom: 10,
}
// 34.053345, -118.242349 LA
// 34.146361, -118.248235 Glendale
// 34.204595, -118.198154 La Cañada
// median of 20230419 data
// 33.9596005477, -118.1973770785

const ShootingsMap = () => {
  const [mapContainer, setMapContainer] = useState(null)
  const mapRef = useCallback(node => setMapContainer(node), [])

  // fetch data
  const data = useStaticQuery(graphql`
    query shootingsMap {
      ShootingsMap: allAirtable(
        filter: { queryName: { eq: "ShootingsMap" } }
        sort: { fields: rowIndex }
      ) {
        edges {
          node {
            data {
              Case_Number
              Date_of_Incident
              Latitude_and_Longitude
              Name_of_Victim
              Officer_Involved___affiliation____of_known_shootings_
              Outcome__Cause_of_Death
            }
            id
            recordId
          }
        }
      }
    }
  `)

  // prepare data
  const points = data.ShootingsMap.edges.map(({ node }) => {
    const outcome = node.data.Outcome__Cause_of_Death
    let nov = node.data.Name_of_Victim

    if (outcome === "Gunshot" || outcome === "Gunshot (self-inflicted)") {
      nov = `${nov}†`
    }

    if (node.data.Latitude_and_Longitude) {
      const coords = node.data.Latitude_and_Longitude.split(", ")

      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ]

      let incident = node.data.Date_of_Incident
      let incidentDate = new Date(incident + "T00:00:00Z")
      incident = `${
        months[incidentDate.getUTCMonth()]
      } ${incidentDate.getUTCDate()} ${incidentDate.getUTCFullYear()}`

      let offcase = `Officer responsible: ${node.data.Officer_Involved___affiliation____of_known_shootings_}<br />Case number: ${node.data.Case_Number}`

      return [
        incident,
        nov,
        parseFloat(coords[0]),
        parseFloat(coords[1]),
        offcase,
      ]
    }

    return []
  })

  const onLoad = useCallback(
    map => {
      // eslint-disable-next-line no-undef
      const infoWindow = new google.maps.InfoWindow({})

      const markers = points.map(([date, name, lat, lng, offcase]) => {
        // create svg url with relative size
        /* commented out here and below in `icon`: 
        // was working in safari but disappeared in ff and tiny in chrome
        // probably should shrink viewBox (closer) to display size and
        // test again
        const svgMarker = window.btoa(`
      <svg fill="#600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
        <path opacity=".8" d="M384 192c0 87.4-117 243-168.3 307.2c-12.3 15.3-35.1 15.3-47.4 0C117 435 0 279.4 0 192C0 86 86 0 192 0S384 86 384 192z"/>
      </svg>`)
      */
        // eslint-disable-next-line no-undef
        const marker = new google.maps.Marker({
          position: { lat, lng },
          // icon: {
          //   url: `data:image/svg+xml;base64,${svgMarker}`,
          //   // eslint-disable-next-line no-undef
          //   scaledSize: new google.maps.Size(18, 24),
          // },
        })

        marker.addListener("click", () => {
          infoWindow.setPosition({ lat, lng })
          infoWindow.setContent(`
        <div class="info-window">
          <h3 class="mb-1">${name}</h3>
          <p class="lead mb-2">${date}</p>
          <p class="mb-0">${offcase}</p>
        </div>`)
          infoWindow.open({ map })
        })

        return marker
      })

      const renderer = {
        render: function ({ count, position }, stats) {
          const defaultSize = 180
          let clusterSize = (count / stats.clusters.markers.max) * defaultSize

          if (clusterSize < defaultSize / 2.5) {
            clusterSize = defaultSize / 2.5
          }

          const scaledClusterSize = Math.floor(clusterSize * 0.625)
          const clusterRadiusSize = Math.floor(clusterSize * 0.58333333)
          const enlargedClusterSize = Math.floor(clusterSize * 2)

          // create svg url with relative size
          const svgCluster = window.btoa(`
      <svg fill="#600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${enlargedClusterSize} ${enlargedClusterSize}">
        <circle cx="${clusterSize}" cy="${clusterSize}" opacity=".8" r="${clusterRadiusSize}" />    
      </svg>`)
          // eslint-disable-next-line no-undef
          return new google.maps.Marker({
            icon: {
              url: `data:image/svg+xml;base64,${svgCluster}`,
              // eslint-disable-next-line no-undef
              scaledSize: new google.maps.Size(
                scaledClusterSize,
                scaledClusterSize
              ),
            },
            label: { text: String(count), color: "white", fontSize: "10px" },
            position,
            // adjust zIndex to be above other markers
            // eslint-disable-next-line no-undef
            zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
          })
        },
      }

      new MarkerClusterer({
        map,
        markers,
        renderer,
        algorithm: new SuperClusterAlgorithm({ radius: 160 }),
      })
    },
    [points]
  )

  return (
    <GoogleMapsProvider
      googleMapsAPIKey={process.env.GATSBY_GMAPS_API_KEY}
      mapContainer={mapContainer}
      mapOptions={mapDefaults}
      onLoadMap={onLoad}
    >
      <MapCanvas ref={mapRef} />
    </GoogleMapsProvider>
  )
}

export default ShootingsMap
