import { graphql, useStaticQuery } from "gatsby"

export const useSiteMetadata = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          description
          image
          imageHeight
          imageWidth
          locale
          siteName
          siteUrl
          themeColor
          title
          twitterCard
          twitterSite
          twitterCreator
        }
      }
    }
  `)

  return data.site.siteMetadata
}
