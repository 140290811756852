import React from "react"
import { Input, Table } from "antd"
import { gangColumns } from "./gangColumns"

export class GangsTable extends React.Component {
  constructor(props) {
    super(props)

    const flattenNodes = (array, result = []) => {
      array.forEach(val => {
        result.push(val.node.data)
      })

      return result
    }

    const gqlNodes = flattenNodes(props.gqlData.edges)

    this.state = {
      filterTable: null,
      columns: gangColumns,
      baseData: gqlNodes,
    }
  }

  search = value => {
    const { baseData } = this.state

    const filterTable = baseData.filter(o =>
      Object.keys(o).some(k =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    )

    this.setState({ filterTable })
  }

  render() {
    const { filterTable, columns, baseData } = this.state

    return (
      <>
        <Input.Search
          allowClear
          enterButton
          onSearch={this.search}
          placeholder="Search by..."
          style={{ margin: "0 0 16px 0" }}
        />
        <Table
          // bordered
          columns={columns}
          dataSource={filterTable == null ? baseData : filterTable}
          // onChange={tableOnChange}
          pagination={false}
          scroll={{ x: true }}
          style={{ whiteSpace: "pre" }}
          // title={() => 'LASD Gangs'}
        />
      </>
    )
  }
}
