import React from "react"
import { graphql, navigate } from "gatsby"
import Layout from "../components/layout"
import ShootingsMap from "../components/map/shootingsMap"
import { GangsTable } from "../components/table/gangsTable"
import { ShootingsTable } from "../components/table/shootingsTable"
import { SEO } from "../components/seo"
import { Tabs } from "antd"

const tabsOnChange = key => {
  navigate(`/#${key}`)
}

export default function Home({ data, location }) {
  const tabHash = location.hash.slice(1)

  const shootingMapTab = (
    <>
      <p>
        This map illustrates the location of all incidents of Los Angeles County
        Sheriff’s Department (LASD) personnel firing their weapons at people for
        which records could be obtained. Many are presumed to be missing. The
        map will continue to update as records become available. To learn more,
        read{" "}
        <a href="https://knock-la.com/life-under-the-gun/">
          Life Under the Gun: An Analysis of Shootings by the Los Angeles County
          Sheriff’s Department
        </a>
        . If you would like to support this effort, you can{" "}
        <a href="https://www.patreon.com/KNOCK_LA">
          donate to our&nbsp;Patreon
        </a>
        .
      </p>
      <ShootingsMap />
    </>
  )

  const shootingDataTab = (
    <>
      <p>
        This database maintains a record of all incidents of Los Angeles County
        Sheriff’s Department (LASD) personnel firing their weapons at people for
        which records could be obtained. Many are presumed to be missing. The
        database will continue to update as records become available. We
        encourage you&nbsp;to:
      </p>
      <ul>
        <li>Search for deputies you've seen in your&nbsp;neighborhood</li>
        <li>Search for deputies you've heard about in the&nbsp;news</li>
        <li>Compare shootings in your neighborhood to&nbsp;others</li>
        <li>Explore how some groups are shot more than&nbsp;others</li>
      </ul>
      <p>
        To learn more, read{" "}
        <a href="https://knock-la.com/life-under-the-gun/">
          Life Under the Gun: An Analysis of Shootings by the Los Angeles County
          Sheriff’s Department
        </a>
        . If you would like to support this effort, you can{" "}
        <a href="https://www.patreon.com/KNOCK_LA">donate to our Patreon</a>. To
        narrow results, use the search field&nbsp;below.
      </p>
      <ShootingsTable gqlData={data.Shootings} />
    </>
  )

  const gangTab = (
    <>
      <p>
        This database of hundreds of LASD employees affiliated with deputy gang
        activity was made possible through hundreds of hours of research, the
        vast majority of which was unpaid. We will continue to update this
        resource with new information as we confirm it, and it will always be
        free to access. If you would like to support this effort, you can{" "}
        <a href="https://www.patreon.com/KNOCK_LA">donate to our Patreon</a>. To
        learn more about deputy gangs, read{" "}
        <a href="https://knock-la.com/tradition-of-violence-lasd-gang-history/">
          A Tradition of Violence
        </a>
        , the investigative series by Cerise Castle,{" "}
        <a href="https://linktr.ee/atraditionofviolence">
          listen to the podcast
        </a>{" "}
        and join the{" "}
        <a href="https://www.patreon.com/lasdgangs">LASD Gangs Patreon</a> to
        support this research and journalism, and access exclusive bonus
        content. To narrow results, use the search field below or select the{" "}
        <span
          role="img"
          aria-label="filter"
          className="va-0 anticon anticon-filter"
        >
          <svg
            viewBox="64 64 896 896"
            focusable="false"
            data-icon="filter"
            width="1em"
            height="1em"
            fill="currentColor"
            aria-hidden="true"
          >
            <path d="M349 838c0 17.7 14.2 32 31.8 32h262.4c17.6 0 31.8-14.3 31.8-32V642H349v196zm531.1-684H143.9c-24.5 0-39.8 26.7-27.5 48l221.3 376h348.8l221.3-376c12.1-21.3-3.2-48-27.7-48z"></path>
          </svg>
        </span>
        &nbsp;icon to filter the Gang and Department columns.{" "}
        <a href="https://knock-la.com/lasd-gang-database-glossary">
          Glossary of terms and&nbsp;abbreviations
        </a>
      </p>
      <GangsTable gqlData={data.Gangs} />
    </>
  )

  const items = [
    {
      label: "Shootings Map",
      key: "shootingsMap",
      children: shootingMapTab,
    },
    {
      label: "Shootings Data",
      key: "shootingsData",
      children: shootingDataTab,
    },
    { label: "LASD Gang Affiliates", key: "gangs", children: gangTab },
  ]

  return (
    <Layout>
      <div id="view">
        <Tabs
          activeKey={
            tabHash === `gangs` ||
            tabHash === `shootingsData` ||
            tabHash === `shootingsMap`
              ? tabHash
              : `shootingsMap`
          }
          defaultActiveKey="shootingsMap"
          items={items}
          onChange={tabsOnChange}
          type="card"
        />
      </div>
    </Layout>
  )
}

// eslint-disable-next-line
export const Head = () => <SEO />

export const pageQuery = graphql`
  query allLASD {
    Gangs: allAirtable(filter: { queryName: { eq: "Gangs" } }) {
      edges {
        node {
          data {
            assignments
            bio
            cases
            dbsnum
            gang
            key
            name
          }
          id
          recordId
        }
      }
    }
    Shootings: allAirtable(
      filter: { queryName: { eq: "ShootingsMap" } }
      sort: { fields: rowIndex }
    ) {
      edges {
        node {
          data {
            Age
            Case_Number
            Date_of_Incident
            Latitude_and_Longitude
            Location
            Name_of_Victim
            Officer_Involved___affiliation____of_known_shootings_
            Outcome__Cause_of_Death
            Race
            Station_Assignment_at_Incident
            Weapon__per_LASD_
          }
          id
          recordId
        }
      }
    }
  }
`
