import React from "react"
import { createMarkup, safeSorter } from "./utils"

export const gangColumns = [
  {
    key: "A",
    title: "Name",
    dataIndex: "name",
    sorter: (a, b) => safeSorter(a.name, b.name),
  },
  {
    key: "B",
    title: "Gang Affiliation, Title",
    dataIndex: "gang",
    sorter: (a, b) => safeSorter(a.gang, b.gang),
    filters: [
      {
        text: "Little Devils",
        value: "Little Devils",
      },
      {
        text: "Vikings",
        value: "Vikings",
      },
      {
        text: "Regulators",
        value: "Regulators",
      },
      {
        text: "3000 Boys",
        value: "3000 Boys",
      },
      {
        text: "2000 Boys",
        value: "2000 Boys",
      },
      {
        text: "Grim Reapers",
        value: "Grim Reapers",
      },
      {
        text: "Wayside Whities",
        value: "Wayside Whities",
      },
      {
        text: "Cavemen",
        value: "Cavemen",
      },
      {
        text: "Banditos",
        value: "Banditos",
      },
      {
        text: "Jump Out Boys",
        value: "Jump Out Boys",
      },
      {
        text: "Spartans",
        value: "Spartans",
      },
      {
        text: "Cowboys",
        value: "Cowboys",
      },
      {
        text: "Executioners",
        value: "Executioners",
      },
    ],
    filterMultiple: true,
    onFilter: (value, record) =>
      record.gang.toLowerCase().indexOf(value.toLowerCase()) >= 0,
  },
  {
    key: "C",
    title: "Case Number(s)",
    dataIndex: "cases",
    sorter: (a, b) => safeSorter(a.cases, b.cases),
  },
  {
    key: "D",
    title: "Deputy/Badge/Serial Number",
    dataIndex: "dbsnum",
    sorter: (a, b) => safeSorter(a.dbsnum, b.dbsnum),
  },
  {
    key: "E",
    title: "Department/Official Title, Assignment",
    dataIndex: "assignments",
    filters: [
      {
        text: "Deputy",
        value: "Deputy",
      },
      {
        text: "Sergeant",
        value: "Sergeant",
      },
      {
        text: "Lieutenant",
        value: "Lieutenant",
      },
      {
        text: "Captain",
        value: "Captain",
      },
      {
        text: "Commander",
        value: "Commander",
      },
      {
        text: "Chief",
        value: "Chief",
      },
      {
        text: "Sheriff",
        value: "Sheriff",
      },
    ],
    // filterMultiple: false,
    onFilter: (value, record) =>
      record.assignments.toLowerCase().indexOf(value.toLowerCase()) >= 0,
    render: html => <div dangerouslySetInnerHTML={createMarkup(html)} />,
    sorter: (a, b) => safeSorter(a.assignments, b.assignments),
  },
  {
    key: "F",
    title: "Bio",
    dataIndex: "bio",
    render: html => <div dangerouslySetInnerHTML={createMarkup(html)} />,
    sorter: (a, b) => safeSorter(a.bio, b.bio),
  },
]
