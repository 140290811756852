import React from "react"
import { Input, Table } from "antd"
import { shootingColumns } from "./shootingColumns"

export class ShootingsTable extends React.Component {
  constructor(props) {
    super(props)

    const flattenNodes = (array, result = []) => {
      array.forEach(val => {
        val.node.data.id = val.node.id
        val.node.data.key = val.node.recordId

        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ]

        let incident = val.node.data.Date_of_Incident
        let incidentDate = new Date(incident + "T00:00:00Z")
        incident = `${
          months[incidentDate.getUTCMonth()]
        } ${incidentDate.getUTCDate()} ${incidentDate.getUTCFullYear()}`
        val.node.data.Date_of_Incident = incident

        result.push(val.node.data)
      })

      return result
    }

    const gqlNodes = flattenNodes(props.gqlData.edges)

    this.state = {
      filterTable: null,
      columns: shootingColumns,
      baseData: gqlNodes,
    }
  }

  search = value => {
    const { baseData } = this.state

    const filterTable = baseData.filter(o =>
      Object.keys(o).some(k =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    )

    this.setState({ filterTable })
  }

  render() {
    const { filterTable, columns, baseData } = this.state

    return (
      <>
        <Input.Search
          allowClear
          enterButton
          onSearch={this.search}
          placeholder="Search by..."
          style={{ margin: "0 0 16px 0" }}
        />
        <Table
          // bordered
          columns={columns}
          dataSource={filterTable == null ? baseData : filterTable}
          // onChange={tableOnChange}
          pagination={false}
          scroll={{ x: true }}
          style={{ whiteSpace: "pre" }}
          // title={() => 'LASD Shootings'}
        />
      </>
    )
  }
}
