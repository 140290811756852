import React from "react"
import { useSiteMetadata } from "../hooks/use-site-metadata"

export const SEO = ({ children, description, pathname, title }) => {
  const {
    description: defaultDescription,
    image,
    imageHeight,
    imageWidth,
    locale,
    siteName,
    siteUrl,
    themeColor,
    title: defaultTitle,
    twitterCard,
    twitterCreator,
    twitterSite,
  } = useSiteMetadata()

  const seo = {
    description: description || defaultDescription,
    favicon: `${siteUrl}/favicon.png`,
    image: `${siteUrl}${image}`,
    imageHeight,
    imageWidth,
    locale,
    manifest: `${siteUrl}/manifest.json`,
    siteName,
    title: title || defaultTitle,
    themeColor,
    twitterCard,
    twitterCreator,
    twitterSite,
    url: `${siteUrl}${pathname || ``}`,
  }

  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="theme-color" content={seo.themeColor} />
      {/* <link rel="manifest" href={seo.manifest} /> */}
      <link rel="icon" href={seo.favicon} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />
      <meta property="og:image:height" content={seo.imageHeight} />
      <meta property="og:image:width" content={seo.imageWidth} />
      <meta property="og:locale" content={seo.locale} />
      <meta property="og:site_name" content={seo.siteName} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={seo.url} />
      <meta name="twitter:card" content={seo.twitterCard} />
      <meta name="twitter:creator" content={seo.twitterCreator} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:site" content={seo.twitterSite} />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      {children}
    </>
  )
}
